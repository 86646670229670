<template>
  <div>
    <SectionHeading
      :title="viewType === 'new' ? 'New' : 'Edit'"
      iconName="TrendingUpIcon"
    />
    <LoadingSpinner v-if="viewType === 'edit' && dataLoading" />
    <div class="max-w-7xl py-10 sm:px-6 lg:px-8">
      <FormSection
        @submitted="viewType === 'new' ? addPromotion() : updatePromotion()"
      >
        <template #title>
          {{ $t('views.marketing.promotions.title') }}
        </template>

        <template #description>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Assumenda,
          at.
        </template>

        <template #form>
          <div class="col-span-6">
            <Label for="identifier" value="Kod promocji" />
            <Input
              id="identifier"
              type="text"
              class="mt-1 block w-full"
              v-model="promotionData.identifier"
            />
            <InputError
              :message="promotionData.errors.get('identifier')"
              class="mt-2"
            />
          </div>

          <div class="col-span-6">
            <Label for="priority" value="Priorytet (1-99)" />
            <Input
              id="priority"
              type="number"
              class="mt-1 block w-full"
              v-model="promotionData.priority"
            />
            <InputError
              :message="promotionData.errors.get('priority')"
              class="mt-2"
            />
          </div>

          <div class="col-span-6">
            <Label for="starts_at" value="Data rozpoczęcia" />
            <DatePicker
              class="inline-block mt-1 w-full"
              v-model="promotionData.starts_at"
            >
              <template v-slot="{ inputValue, togglePopover }">
                <div class="flex items-center" @click="togglePopover()">
                  <button
                    type="button"
                    class="px-2 py-3 h-full bg-blue-100 border border-blue-200 hover:bg-blue-200 text-blue-600 rounded-bl-md rounded-tl-md focus:bg-blue-500 focus:text-white focus:border-blue-500 focus:outline-none"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      class="w-4 h-4 fill-current"
                    >
                      <path
                        d="M1 4c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4zm2 2v12h14V6H3zm2-6h2v2H5V0zm8 0h2v2h-2V0zM5 9h2v2H5V9zm0 4h2v2H5v-2zm4-4h2v2H9V9zm0 4h2v2H9v-2zm4-4h2v2h-2V9zm0 4h2v2h-2v-2z"
                      ></path>
                    </svg>
                  </button>
                  <input
                    :value="inputValue"
                    class="block w-full border border-gray-300 rounded-br-md rounded-tr-md shadow-sm py-2 px-3 focus:outline-none"
                  />
                </div>
              </template>
            </DatePicker>
            <InputError
              :message="promotionData.errors.get('starts_at')"
              class="mt-2"
            />
          </div>

          <div class="col-span-6">
            <Label for="should_expire" value="Czy promocja wygasa" />
            <TToggle
              id="should_expire"
              class="mt-1 block"
              v-model="promotionData.should_expire"
            />
            <InputError
              :message="promotionData.errors.get('should_expire')"
              class="mt-2"
            />
          </div>

          <div class="col-span-6">
            <Label for="ends_at" value="Data końca" />
            <DatePicker
              class="inline-block mt-1 w-full"
              v-model="promotionData.ends_at"
            >
              <template v-slot="{ inputValue, togglePopover }">
                <div class="flex items-center" @click="togglePopover()">
                  <button
                    type="button"
                    class="px-2 py-3 h-full bg-blue-100 border border-blue-200 hover:bg-blue-200 text-blue-600 rounded-bl-md rounded-tl-md focus:bg-blue-500 focus:text-white focus:border-blue-500 focus:outline-none"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      class="w-4 h-4 fill-current"
                    >
                      <path
                        d="M1 4c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4zm2 2v12h14V6H3zm2-6h2v2H5V0zm8 0h2v2h-2V0zM5 9h2v2H5V9zm0 4h2v2H5v-2zm4-4h2v2H9V9zm0 4h2v2H9v-2zm4-4h2v2h-2V9zm0 4h2v2h-2v-2z"
                      ></path>
                    </svg>
                  </button>
                  <input
                    :value="inputValue"
                    class="block w-full border border-gray-300 rounded-br-md rounded-tr-md shadow-sm py-2 px-3 focus:outline-none"
                  />
                </div>
              </template>
            </DatePicker>
            <InputError
              :message="promotionData.errors.get('ends_at')"
              class="mt-2"
            />
          </div>

          <div class="col-span-6">
            <Label for="type" value="Rodzaj promocji" />
            <TSelect
              id="gender"
              :options="promotionTypeList"
              class="mt-1 block w-full"
              v-model="promotionData.type"
              textAttribute="label"
            />
            <InputError
              :message="promotionData.errors.get('type')"
              class="mt-2"
            />
          </div>

          <div class="col-span-6">
            <Label for="discount_value" value="Wartość zniżki" />
            <Input
              id="discount_value"
              type="number"
              step="0.1"
              class="mt-1 block w-full"
              v-model="promotionData.discount_value"
            />
            <InputError
              :message="promotionData.errors.get('discount_value')"
              class="mt-2"
            />
          </div>

          <SectionBorder class="col-span-6" />

          <div class="col-span-6 -mt-6">
            <h3 class="text-lg leading-6 font-medium text-gray-900 pb-2 mb-6">
              Tłumaczenia
            </h3>
            <TabGroup>
              <TabList class="flex flex-row bg-gray-100 rounded-t-md">
                <Tab
                  v-for="translation in translationsList"
                  as="div"
                  :key="translation.id"
                  v-slot="{ selected }"
                >
                  <div
                    :class="[
                      selected
                        ? 'border-indigo-500 text-indigo-600'
                        : 'border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700',
                      'flex whitespace-nowrap border-b-2 py-4 px-6 text-sm font-medium',
                      'cursor-pointer',
                    ]"
                  >
                    {{ translation.label }}
                    <span
                      v-if="
                        promotionData.errors.get(
                          `translations.${translation.value}.name`
                        )
                      "
                      :class="[
                        'bg-red-100 text-red-600',
                        'ml-3 hidden rounded-full py-0.5 px-2.5 text-xs font-bold md:inline-block animate-pulse',
                      ]"
                      >!
                    </span>
                  </div>
                </Tab>
              </TabList>
              <TabPanels class="border-2 border-gray-100 rounded-b-md">
                <TabPanel
                  :key="translation.value"
                  v-for="translation in translationsList"
                  class="flex flex-col gap-2 p-4"
                >
                  <div>
                    <Label value="Nazwa" />
                    <Input
                      type="text"
                      class="mt-1 block w-full"
                      :value="
                        inputValue('translations', translation.value, 'name')
                      "
                      @input="
                        inputEvent(
                          $event,
                          'translations',
                          translation.value,
                          'name'
                        )
                      "
                    />
                    <InputError
                      :message="
                        promotionData.errors.get(
                          'translations.' + translation.value + '.name'
                        )
                      "
                      class="mt-2"
                    />
                  </div>
                </TabPanel>
              </TabPanels>
            </TabGroup>
          </div>

          <SectionBorder class="col-span-6" />

          <div class="col-span-6 -mt-6">
            <Label for="categories" value="Kategorie" />
            <TRichSelect
              id="categories"
              :fetchOptions="categoriesFetchOptions"
              :prefetchOptions="categoriesPrefetchOptions"
              v-model="promotionData.promotionable.categories"
              textAttribute="name"
              valueAttribute="id"
              placeholder="Wybierz kategorię"
              multiple
              tags
              class="mt-1 block w-full"
            />
            <InputError
              :message="promotionData.errors.get('promotionable.categories')"
              class="mt-2"
            />
          </div>

          <div class="col-span-6">
            <Label for="products" value="Produkty" />
            <TRichSelect
              id="products"
              :fetchOptions="productsFetchOptions"
              :prefetchOptions="productsPrefetchOptions"
              v-model="promotionData.promotionable.products"
              textAttribute="name"
              valueAttribute="id"
              placeholder="Wybierz produkt"
              multiple
              tags
              class="mt-1 block w-full"
            />
            <InputError
              :message="promotionData.errors.get('promotionable.products')"
              class="mt-2"
            />
          </div>

          <SectionBorder class="col-span-6" />

          <div class="col-span-6 -mt-6">
            <h3 class="text-lg leading-6 font-medium text-gray-900 pb-2 mb-6">
              Reguły
            </h3>
            <InputError
              :message="promotionData.errors.get('rules')"
              class="mb-4"
            />
            <div
              v-for="(rule, i) in promotionData.rules"
              :key="i"
              class="mb-8 space-y-4"
            >
              <div>
                <Label value="Typ" />
                <TSelect
                  :options="promotionRuleTypeList"
                  class="mt-1 block w-full"
                  v-model="promotionData.rules[i].type"
                  valueAttribute="value"
                  textAttribute="label"
                />
                <InputError
                  :message="promotionData.errors.get('rules.' + i + '.type')"
                  class="mt-2"
                />
              </div>
              <div>
                <Label value="Wartość" />
                <Input
                  type="number"
                  class="mt-1 block w-full"
                  v-model="promotionData.rules[i].value"
                />
                <InputError
                  :message="promotionData.errors.get('rules.' + i + '.value')"
                  class="mt-2"
                />
              </div>
              <Input type="hidden" v-model="promotionData.rules[i].id" />
              <DynamicIconSolid
                @click="removeRule(i)"
                icon="TrashIcon"
                class="text-red-500 h-6 w-6 mt-2 mb-6 cursor-pointer"
                aria-hidden="true"
              />
            </div>

            <SecondaryButton @click="addRule">
              <DynamicIconSolid
                icon="PlusIcon"
                class="mr-2 -ml-1 h-5 w-5"
                aria-hidden="true"
              />
              Dodaj Zasadę
            </SecondaryButton>
          </div>
        </template>

        <template #actions>
          <ActionMessage :on="promotionData.recentlySuccessful" class="mr-3">
            Saved.
          </ActionMessage>

          <Button
            :class="{ 'opacity-25': promotionData.busy }"
            :disabled="promotionData.busy"
          >
            {{ viewType === 'new' ? 'Dodaj' : 'Aktualizuj' }}
          </Button>
        </template>
      </FormSection>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import PromotionsService from '@/service/promotions/promotions.js';
import { useRoute } from 'vue-router';
import router from '@/router';
import LoadingSpinner from '@/components/@globals/helpers/LoadingSpinner';
import FormSection from '../../../components/@globals/helpers/FormSection';
import Input from '../../../components/@globals/helpers/Input';
import InputError from '../../../components/@globals/helpers/InputError';
import Button from '../../../components/@globals/helpers/Button';
import ActionMessage from '../../../components/@globals/helpers/ActionMessage';
import Label from '../../../components/@globals/helpers/Label';
import { TToggle } from '@variantjs/vue';
import Form from 'vform';
import {
  PROMOTION_RULE_TYPE_LIST,
  PROMOTION_TYPE_LIST,
} from './promotion-constants';
import { TSelect } from '@variantjs/vue';
import SectionBorder from '../../../components/@globals/helpers/SectionBorder';
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/vue';
import { TRANSLATIONS } from '../categories/category-constants';
import { get, set } from 'lodash';
import { useStore } from 'vuex';
import { TRichSelect } from '@variantjs/vue';
import SecondaryButton from '../../../components/@globals/helpers/SecondaryButton';
import axios from 'axios';

const store = useStore();
const {
  params: { id },
} = useRoute();
const viewType = ref('');
const dataLoading = ref(false);
const promotionData = ref(
  Form.make({
    identifier: '',
    starts_at: '',
    ends_at: null,
    should_expire: true,
    type: '',
    discount_value: null,
    priority: null,
    translations: {},
    rules: [],
    promotionable: {
      categories: [],
      products: [],
    },
  })
);
const translationsList = TRANSLATIONS;
const promotionTypeList = PROMOTION_TYPE_LIST;
const promotionRuleTypeList = PROMOTION_RULE_TYPE_LIST;

store.dispatch('getCategories', {});

store.dispatch('getProducts', { paginate: 0 });

if (id === 'new') {
  viewType.value = 'new';
} else {
  viewType.value = 'edit';
  dataLoading.value = true;
  PromotionsService.getPromotionDetails(id).then((response) => {
    promotionData.value.fill(response.data.data);

    set(
      promotionData.value,
      'promotionable.categories',
      response.data.data.refers_to.categories.map((category) => category.id)
    );

    set(
      promotionData.value,
      'promotionable.products',
      response.data.data.refers_to.products.map((product) => product.id)
    );

    promotionData.value.rules = response.data.data.rules.map((rule) => ({
      type: rule.promotion_rule,
      value: rule.value,
      id: rule.id,
    }));

    dataLoading.value = false;
  });
}

function addPromotion() {
  PromotionsService.addPromotion(promotionData.value).then(() => {
    router.push({ name: 'PromotionsList' });
  });
}

function updatePromotion() {
  console.log(promotionData.value);
  PromotionsService.updatePromotion(id, promotionData.value).then(() => {
    router.push({ name: 'PromotionsList' });
  });
}

function translationErrorExist(...path) {
  return Object.keys(promotionData.value.errors.all()).filter(function (key) {
    return key.includes(path.join('.'));
  }).length;
}

const disclosureState = ref({});
watch(
  () => promotionData.value.errors,
  () => {
    translationsList.forEach(function (translation) {
      if (
        translationErrorExist('translations', translation.value) ||
        localStorage.getItem('app_lang') === translation.value
      ) {
        set(disclosureState.value, translation.value, true);
      }
    });
  },
  { immediate: true, deep: true }
);

function inputValue(...fieldName) {
  return get(promotionData.value, fieldName, null);
}

function inputEvent(event, ...fieldName) {
  set(promotionData.value, fieldName, event.target.value);
}

function addRule() {
  promotionData.value.rules.push({
    type: '',
    value: null,
    id: null,
  });
}

const productsFetchOptions = async (query, nextPage) => {
  try {
    const response = await axios.get(`/panel/api/v1/products`, {
      params: {
        search: query,
        page: nextPage || 1,
      },
    });

    return {
      results: response.data.data.map((c) => ({
        id: c.id,
        name: c.name,
      })),
      hasMorePages:
        response.meta && response.meta.current_page < response.meta.last_page,
    };
  } catch (error) {
    console.log(error);

    return {
      results: [],
      hasMorePages: false,
    };
  }
};

const productsPrefetchOptions = async () => {
  const response = await axios.get(`panel/api/v1/products`, {
    params: {
      only_root: false,
      promotion_id: id === 'new' ? null : id,
    },
  });

  return response.data.data.map((c) => ({
    id: c.id,
    name: c.name,
  }));
};

const categoriesFetchOptions = async (query, nextPage) => {
  try {
    const response = await axios.get(`/panel/api/v1/categories`, {
      params: {
        only_root: false,
        keyword: query,
        page: nextPage || 1,
      },
    });

    return {
      results: response.data.data.map((c) => ({
        id: c.id,
        name: c.name,
      })),
      hasMorePages:
        response.meta && response.meta.current_page < response.meta.last_page,
    };
  } catch (error) {
    console.log(error);

    return {
      results: [],
      hasMorePages: false,
    };
  }
};

const categoriesPrefetchOptions = async () => {
  const response = await axios.get(`panel/api/v1/categories`, {
    params: {
      only_root: false,
      promotion_id: id === 'new' ? null : id,
    },
  });

  return response.data.data.map((c) => ({
    id: c.id,
    name: c.name,
  }));
};

function removeRule(index) {
  promotionData.value.rules.splice(index, 1);
}
</script>

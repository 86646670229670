<template>
  <div>
    <SectionHeading
        :title="$t('views.sales.orderDetails.title')"
        iconName="ShoppingBagIcon"
        subTitle="See order details"
    />
    <LoadingSpinner v-if="dataLoading"/>
    <div class="flex flex-col sm:-mx-6 lg:-mx-8" v-if="dataLoading === false">
      <div class="my-2 overflow-x-auto">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="shadow overflow-hidden border-b border-gray-200 relative">
            <LoadingSpinner v-if="dataloading"/>
            <div class="bg-white px-4 py-5 sm:px-6 border-b border-gray-200">
              <h3 class="text-lg leading-6 font-medium text-gray-900">
                Pozycje zamówienia
              </h3>
              <p class="mt-1 max-w-2xl text-sm text-gray-500"></p>
            </div>
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
              <tr>
                <th scope="col" class="customers-list__col flex items-center">
                  <div class="flex items-center">
                    <span>ID</span>
                    <Sort
                        keyValue="id"
                        @valueChanges="sortHandler($event)"
                        :currentSort="currentSort == 'id'"
                    />
                  </div>
                </th>
                <th scope="col" class="customers-list__col">
                  <div class="flex items-center">
                    <span>Miniatura</span>
                  </div>
                </th>
                <th scope="col" class="customers-list__col">
                  <div class="flex items-center">
                    <span>Nazwa produktu</span>
                    <Sort
                        keyValue="name"
                        @valueChanges="sortHandler($event)"
                        :currentSort="currentSort == 'name'"
                    />
                  </div>
                </th>
                <th scope="col" class="customers-list__col">
                  <div class="flex items-center">
                    <span>Ilość</span>
                    <Sort
                        keyValue="category"
                        @valueChanges="sortHandler($event)"
                        :currentSort="currentSort == 'quantity'"
                    />
                  </div>
                </th>
                <th scope="col" class="customers-list__col">
                  <div class="flex items-center">
                    <span>Cena</span>
                    <Sort
                        keyValue="price"
                        @valueChanges="sortHandler($event)"
                        :currentSort="currentSort == 'price'"
                    />
                  </div>
                </th>
                <th scope="col" class="customers-list__col">
                  <div class="flex items-center">
                    <span>Waluta</span>
                    <Sort
                        keyValue="currency"
                        @valueChanges="sortHandler($event)"
                        :currentSort="currentSort == 'currency'"
                    />
                  </div>
                </th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="(item, index) in orderData.items"
                  :key="item.id"
                  :class="index % 2 ? 'bg-white' : 'bg-gray-50'"
              >
                <td
                    class="
                      px-6
                      py-4
                      whitespace-nowrap
                      text-sm
                      font-medium
                      text-gray-900
                    "
                >
                  {{ item.id }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  <div class="flex items-center">
                    <div class="flex-shrink-0 h-10 w-10">
                      <img
                          class="object-cover w-full h-full"
                          :src="item.product.media[0]?.variants[0]?.url === undefined  ? '/assets/placeholder_image.png' : item.product.media[0].variants[0].url"
                          alt=""
                      />
                    </div>
                  </div>
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {{ item.product.name }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {{ item.quantity }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {{ item.product_variant.price }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {{ orderData.currency }}
                </td>
              </tr>

              <tr :class="index % 2 ? 'bg-white' : 'bg-gray-50'">
                <td
                    class="
                      px-6
                      py-4
                      whitespace-nowrap
                      text-sm text-gray-500
                      italic
                    "
                >
                  Cena produktów
                </td>
                <td
                    class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                ></td>
                <td
                    class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                ></td>
                <td
                    class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                ></td>
                <td
                    class="
                      px-6
                      py-4
                      whitespace-nowrap
                      text-sm text-gray-500
                      italic
                    "
                >
                  <strong>{{ orderData.price }}</strong>
                </td>
                <td
                    class="
                      px-6
                      py-4
                      whitespace-nowrap
                      text-sm text-gray-500
                      italic
                    "
                >
                  <strong>{{ orderData.currency }}</strong>
                </td>
              </tr>

              <tr :class="index % 2 ? 'bg-white' : 'bg-gray-50'">
                <td
                    class="
                      px-6
                      py-4
                      whitespace-nowrap
                      text-sm text-gray-500
                      italic
                    "
                >
                  Cena dostawy
                </td>
                <td
                    class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                ></td>
                <td
                    class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                ></td>
                <td
                    class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                ></td>
                <td
                    class="
                      px-6
                      py-4
                      whitespace-nowrap
                      text-sm text-gray-500
                      italic
                    "
                >
                  <strong>{{ orderData.delivery_cost }}</strong>
                </td>
                <td
                    class="
                      px-6
                      py-4
                      whitespace-nowrap
                      text-sm text-gray-500
                      italic
                    "
                >
                  <strong class="italic">{{ orderData.currency }} </strong>
                </td>
              </tr>
              <tr class="bg-white">
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  <strong>Razem</strong>
                </td>
                <td
                    class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                ></td>
                <td
                    class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                ></td>
                <td
                    class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                ></td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  <strong>{{ orderData.total_price }}</strong>
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  <strong>{{ orderData.currency }} </strong>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="grid gap-4 grid-cols-2">
            <OrderDetails :orderData="orderData"/>
            <PaymentDetails :orderPayment="orderPayment"/>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col sm:-mx-6 lg:-mx-8"></div>
  </div>
</template>

<script>
import {ref} from 'vue';
import OrdersService from '@/service/orders/orders.js';
import {useRoute} from 'vue-router';
import OrderDetails from '@/views/main/orders/partials/OrderDetails';
import PaymentDetails from '@/views/main/payments/PaymentDetails.vue';

export default {
  name: 'OrdersShow',
  components: {
    OrderDetails,
    PaymentDetails,
  },
  setup() {
    const {
      params: {id},
    } = useRoute();
    const orderData = ref({});
    const orderPayment = ref({});
    const dataLoading = ref(true);

    OrdersService.getOrdersDetails(id).then((response) => {
      orderData.value = response.data.data;
      orderPayment.value = orderData.value.payment_intent;

      dataLoading.value = false;
    });

    return {
      orderData,
      orderPayment,
      dataLoading,
    };
  },
};
</script>
